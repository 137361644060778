import React from 'react';

import { GAME_STATE, getTotalScore } from '../custom/utils';

const Modal = ({ gameState, score, startGame, timeLeft, resetGame }) => (

  <div className="modal modal-sm active">
    <div className="modal-overlay" />
    <div className="modal-container">
      <div className="modal-header">
        <div className="modal-title h4">Alinhe os úteis</div>
      </div>
      <div className="modal-body">
        <div className="content h6">
          {' '}
          {gameState === GAME_STATE.READY
            ? `Arraste e solte os úteis na lista de quadrinhos correta`
            : `Você pontuou - ${score}`}
        </div>
      </div>
      <div className="modal-footer">
        <button
          className="btn btn-primary"
          onClick={gameState === GAME_STATE.READY ? startGame : resetGame}
        >
          {gameState === GAME_STATE.READY ? 'Começar novo jogo' : 'Reiniciar o jogo'}
        </button>
      </div>
    </div>
  </div>
);

export default Modal;
