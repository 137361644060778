import React from 'react';
import { DragDropContext } from 'react-beautiful-dnd';

import { HEROES, COMICS } from './custom/data';
import { CONTENEDORES, OBJETOS } from './custom/utiles';
import { shuffle, getTimeLeft, move, GAME_STATE } from './custom/utils';

import Modal from './components/Modal';
import Header from './components/Header';
import Dropzone from './components/Dropzone';
import Footer from './components/Footer';

const GAME_DURATION = 1000 * 30; // 30 seconds

const initialState = {
  // we initialize the state by populating the bench with a shuffled collection of heroes
  /*bench: shuffle(HEROES),
  [COMICS.DC]: [],
  [COMICS.MARVEL]: [],
  gameState: GAME_STATE.READY,*/
  bench: shuffle(OBJETOS),
  [CONTENEDORES.MOCHILA]: [],
  [CONTENEDORES.COMPASSO]: [],
  [CONTENEDORES.LAPIS]: [],
  [CONTENEDORES.CADERNO]: [],
  [CONTENEDORES.LAPISEIRA]: [],
  [CONTENEDORES.LIVRO]: [],
  [CONTENEDORES.COLA]: [],
  [CONTENEDORES.REGUA]: [],
  [CONTENEDORES.BORRACHA]: [],
  gameState: GAME_STATE.READY,
  timeLeft: 0,
  score: 0,
};

class App extends React.Component {
  state = initialState;

  startGame = () => {
    //this.currentDeadline = Date.now() + GAME_DURATION;

    this.setState(
      {
        gameState: GAME_STATE.PLAYING,
        //timeLeft: getTimeLeft(this.currentDeadline),
      },
      // this.gameLoop
    );
  };

  gameLoop = () => {
    this.timer = setInterval(() => {
      const timeLeft = getTimeLeft(this.currentDeadline);
      const isTimeout = timeLeft <= 0;
      if (isTimeout && this.timer) {
        clearInterval(this.timer);
      }

      this.setState({
        timeLeft: isTimeout ? 0 : timeLeft,
        ...(isTimeout ? { gameState: GAME_STATE.DONE } : {}),
      });
    }, 1000);
  };

  endGame = () => {
    if (this.timer) {
      clearInterval(this.timer);
    }

    this.setState({
      gameState: GAME_STATE.DONE,
    });
  };

  resetGame = () => {
    this.setState(initialState);
  };

  onDragEnd = ({ source, destination }) => {
    if (!destination) {
      return;
    }

    this.setState(state => {
      return move(state, source, destination);
    });
  };

  render() {
    const { gameState, timeLeft, bench, ...groups } = this.state;
    const isDropDisabled = gameState === GAME_STATE.DONE;

    return (
      <>
        {/*
        <Header gameState={gameState} timeLeft={timeLeft} endGame={this.endGame} />
        */}
        {this.state.gameState !== GAME_STATE.PLAYING && (
          <Modal
            startGame={this.startGame}
            resetGame={this.resetGame}
            timeLeft={timeLeft}
            gameState={gameState}
            score={this.state.score}
          />
        )}
        {(this.state.gameState === GAME_STATE.PLAYING ||
          this.state.gameState === GAME_STATE.DONE) && (
            <DragDropContext onDragEnd={this.onDragEnd}>
              <div className="container">
                <div className="row">
                  <div className="col">
                    <Dropzone
                      id={CONTENEDORES.MOCHILA}
                      heroes={this.state[CONTENEDORES.MOCHILA]}
                      isDropDisabled={isDropDisabled}
                    />
                  </div>
                  <div className="col">
                    <Dropzone
                      id={CONTENEDORES.COMPASSO}
                      heroes={this.state[CONTENEDORES.COMPASSO]}
                      isDropDisabled={isDropDisabled}
                    />
                  </div>
                  <div className="col">
                    <Dropzone
                      id={CONTENEDORES.LAPIS}
                      heroes={this.state[CONTENEDORES.LAPIS]}
                      isDropDisabled={isDropDisabled}
                    />
                  </div>
                  <div className="col">
                    <Dropzone
                      id={CONTENEDORES.CADERNO}
                      heroes={this.state[CONTENEDORES.CADERNO]}
                      isDropDisabled={isDropDisabled}
                    />
                  </div>
                  <div className="col">
                    <Dropzone
                      id={CONTENEDORES.LAPISEIRA}
                      heroes={this.state[CONTENEDORES.LAPISEIRA]}
                      isDropDisabled={isDropDisabled}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <Dropzone
                      id={CONTENEDORES.LIVRO}
                      heroes={this.state[CONTENEDORES.LIVRO]}
                      isDropDisabled={isDropDisabled}
                    />
                  </div>
                  <div className="col">
                    <Dropzone
                      id={CONTENEDORES.COLA}
                      heroes={this.state[CONTENEDORES.COLA]}
                      isDropDisabled={isDropDisabled}
                    />
                  </div>
                  <div className="col">
                    <Dropzone
                      id={CONTENEDORES.REGUA}
                      heroes={this.state[CONTENEDORES.REGUA]}
                      isDropDisabled={isDropDisabled}
                    />
                  </div>
                  <div className="col">
                    <Dropzone
                      id={CONTENEDORES.BORRACHA}
                      heroes={this.state[CONTENEDORES.BORRACHA]}
                      isDropDisabled={isDropDisabled}
                    />
                  </div>
                </div>
                <Dropzone id="bench" heroes={bench} isDropDisabled={isDropDisabled} />

              </div>
            </DragDropContext>
          )}
        <Footer />
      </>
    );
  }

  componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }
}

export default App;
