import React from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';

const Dropzone = ({ isDropDisabled, heroes, id }) => (
  <div>
    {id != 'bench' &&
    <div className="divider" data-content={id.toUpperCase()} /> }
    {id == 'bench' &&
      <div className="divider" data-content="ÚTEIS" />}
    <div>
      {id == 'bench' &&
    <Droppable droppableId={id} isDropDisabled={isDropDisabled}>
      {provided => {
        return (
          <div className="menu hero-list imagenes row" {...provided.droppableProps} ref={provided.innerRef}>
            {heroes.map(({ name, img }, index) => (
              
              <div className="col-sm-6 col-md-4 col-lg-2">
                <Hero key={name} img={img} name={name} index={index} />
              </div>
            ))}
            {provided.placeholder}
          </div>
        );
      }}
    </Droppable>}
      {id != 'bench' &&
        <Droppable droppableId={id} isDropDisabled={isDropDisabled}>
          {provided => {
            return (
              <div className="menu hero-list" {...provided.droppableProps} ref={provided.innerRef}>
                {heroes.map(({ name, img }, index) => (
                  <Hero key={name} img={img} name={name} index={index} />
                ))}
                {provided.placeholder}
              </div>
            );
          }}
        </Droppable>}
    </div>
  </div>
);

const Hero = ({ name, img, index }) => (

  <Draggable key={name} draggableId={name} index={index}>
    {provided => {
      return (
        <div
          className="menu-item tile tile-centered"
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <figure style={{ backgroundColor: 'transparent' }} className="tile-icon">
            <img src={`./utiles/${img.toLowerCase().replace(' ', '-')}.PNG`} alt={name} />
          </figure>
        </div>
      );
    }}
  </Draggable>
);

export default Dropzone;
