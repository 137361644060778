export const CONTENEDORES = {
  MOCHILA: 'mochila',
  COMPASSO: 'compasso',
  LAPIS: 'lápis',
  CADERNO: 'caderno',
  LAPISEIRA: 'lapiseira',
  LIVRO: 'livro',
  COLA: 'cola',
  REGUA: 'régua',
  BORRACHA: 'borracha'
};

export const OBJETOS = [
  {
    name: 'mochila',
    contenedores: 'mochila',
    img: 'mochila',
  },
  {
    name: 'compasso',
    contenedores: 'compasso',
    img: 'compasso',
  },
  {
    name: 'lápis',
    contenedores: 'lápis',
    img: 'lapis',
  },
  {
    name: 'caderno',
    contenedores: 'caderno',
    img: 'caderno',
  },
  {
    name: 'lapiseira',
    contenedores: 'lapiseira',
    img: 'lapiseira',
  },
  {
    name: 'livro',
    contenedores: 'livro',
    img: 'livro',
  },
  {
    name: 'cola',
    contenedores: 'cola',
    img: 'cola',
  },
  {
    name: 'régua',
    contenedores: 'régua',
    img: 'regua',
  },
  {
    name: 'borracha',
    contenedores: 'borracha',
    img: 'borracha',
  },
];
